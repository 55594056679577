var hasOwn = require('./hasOwn');
var every = require('./every');
var isObject = require('../lang/isObject');
var is = require('../lang/is');

// Makes a function to compare the object values from the specified compare
// operation callback.
function makeCompare(callback) {
  return function (value, key) {
    return hasOwn(this, key) && callback(value, this[key]);
  };
}
function checkProperties(value, key) {
  return hasOwn(this, key);
}

/**
 * Checks if two objects have the same keys and values.
 */
function equals(a, b, callback) {
  callback = callback || is;
  if (!isObject(a) || !isObject(b)) {
    return callback(a, b);
  }
  return every(a, makeCompare(callback), b) && every(b, checkProperties, a);
}
module.exports = equals;