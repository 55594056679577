import {ModelBase} from './model-base';
import {
    APIService,
    FacilityDetailedSerializer,
    FacilityEmrImportSettingAccountSerializer,
    FacilityUserAuthSerializer,
    MedicalSpecialtyUserAuthSerializer,
} from '../@core/api.service';
import {GetterCache} from '../utils/accessor.utils';

export interface Address {
    address?: string;
    city?: string;
    state?: string;
    zip_code?: string;
    zip?: string;
}

export class Facility extends ModelBase implements FacilityDetailedSerializer, FacilityUserAuthSerializer {
    id: number;
    name: string;
    created_at: Date;
    size: number;
    timezone: string;
    type: string;
    address: string;
    organization: number;
    users_count: number;
    mac: number;
    import_settings: FacilityEmrImportSettingAccountSerializer;
    lab_import_account: number;
    city: string;
    is_inactive: boolean;
    state: string;
    zip_code: string;
    origin: string;
    from_customer_only: boolean;
    access_permission_type: string;
    phone_number: any;
    specialties: Array<MedicalSpecialtyUserAuthSerializer>;

    static viewSet = APIService.FacilityViewSet;

    assign(x) {
        super.assign(x);
        if (x.created_at && typeof x.created_at === 'string') x.created_at = new Date(x.created_at);
    }

    @GetterCache() get addressString() {
        return Facility.getAddressString(this);
    }

    get noEmr() {
        return ['ARSANA', 'BST'].includes(this.origin);
    }

    static getAddressString(address: Address, multiLine?: boolean): string;
    static getAddressString(address: string, city: string, state: string, zip: string, multiLine?: boolean): string;
    static getAddressString(address: Address | string, cityOrMultiline?: string | boolean, state?: string, zip?: string, multiLine?: boolean): string {
        let city = typeof cityOrMultiline === 'string' ? cityOrMultiline : null;
        multiLine = typeof cityOrMultiline === 'boolean' ? cityOrMultiline : (multiLine ?? true);
        if (typeof address === 'object') {
            city = address.city;
            state = address.state;
            zip = address.zip_code || address.zip;
            address = address.address;
        }

        return `${address || '?'}${multiLine ? '\n' : ', '}${city || '?'}, ${state || '?'} ${zip || '?'}`;
    }
}
